'use client';

import HorizontalSlider from '@/components/horizontal-slider';
import ProductDetail from '@/components/product-detail';
import classNames from 'classnames';
import { League_Gothic } from 'next/font/google';
import React, { useMemo } from 'react';
import { ShopService } from '@/bundles/core/services/shop';


const gothic = League_Gothic({ subsets: ['latin'] });

interface HorizontalProductListProps {
  data: any[];
  title: string;
  subtitle: string;
  callback?: (product: any) => void;
  path?: string;
}

const HorizontalProductList: React.FC<HorizontalProductListProps> = ({ data, title, callback, subtitle, path = '' }) => {
  const shopService = useMemo(() => new ShopService('/api/proxy'), []);

  return (
    <div className="w-full">
      {title &&
        <div className={classNames(gothic.className, 'flex flex-col items-center justify-center text-gray py-0')}>
          <h2 className="text-3xl md:text-6xl uppercase tracking-[.25rem]">{title}</h2>
          <h3 className="">{subtitle}</h3>
        </div>
      }
      <div>
        <HorizontalSlider>
          <div className="flex flex-row items-start gap-5 w-full h-full py-5">
            {Array.isArray(data) && data?.map((p, index) => {
              let price = shopService.getFormattedPrice(p, {});
              if (!price) return;

              let product = {
                id: p?.id,
                name: p?.name,
                category: p?.line?.name,
                price: shopService.getFormattedPrice(p, {}),
                old: undefined,
                images: [p?.thumbnail].concat(p?.gallery),
                sales: true
              };
              return <ProductDetail dimensions={{ width: 200, height: 300 }} key={index} url={`${path}/${p.name}/${p.sku}`} callback={callback} className="md:w-[250px] w-fit h-full" product={product} />;
            })}
          </div>
        </HorizontalSlider>
      </div>
    </div>
  );
};

export default HorizontalProductList;
