'use client';

import classNames from 'classnames';
import Link from 'next/link';
import React, { FC, ReactNode } from 'react';
import { IoMdHeartEmpty as IconUnlike } from 'react-icons/io';

import ImageSlider from '../image-slider';

type ProductType = {
  name: string;
  category?: string;
  price: string | number;
  old?: number;
  images: string[];
};

type ProductDetailProps = {
  product: ProductType;
  children?: ReactNode;
  className?: string;
  sales?: boolean;
  dimensions?: { width: number; height: number };
  callback?: (product: ProductType) => void;
  url?: string;
};

const ProductDetail: FC<ProductDetailProps> = ({ product, className, sales, dimensions, url = '' }) => {
  return (
    <div className={classNames('flex flex-col items-center justify-center bg-white shadow-md rounded-lg p-4', className)}>
      <Link href={url} className="text-center mb-0">
        <h3 className="text-gray text-lg font-bold">{product?.name}</h3>
        <p className="text-gray-light text-sm">{product?.category}</p>
      </Link>
      <div className="flex justify-between w-full py-0">
        {sales && <div className="bg-dark text-white text-xs font-bold py-1 px-2">SALE</div>}
        {/* <button title="" type="button" className="text-gray-400 hover:text-red-500">
          <IconUnlike />
        </button> */}
      </div>
      <Link href={url} className={'flex-1 flex flex-row items-center justify-center'}>
        <ImageSlider images={product?.images} dimensions={dimensions} />
      </Link>
      <div className="flex flex-row items-center justify-center gap-2">
        {product?.old && <p className="text-gray text-sm line-through">{product?.old}</p>}
        <p className="text-dark text-lg font-bold">{product?.price}</p>
      </div>
      <div className="p-1 mt-1 ">
        <Link href={url} className="bg-success text-sm text-white py-2 px-10 rounded-full uppercase w-auto ">
          Comprar
        </Link>
      </div>
    </div>
  );
};

export const ProductDetailSimple: FC<ProductDetailProps & { href: string }> = ({ className, product, href, sales, dimensions, children }) => {
  return (
    <div className={classNames('h-full flex flex-col items-center justify-center bg-white shadow-md rounded-lg p-4', className)}>
      <div className="flex justify-between w-full py-5">
        {sales && <div className="bg-dark text-white text-xs font-bold py-1 px-2">SALE</div>}
        <button className="text-gray-400 hover:text-red-500">
          <IconUnlike />
        </button>
      </div>
      <div className={'flex-1'}>
        <Link href={href || '#'}>
          <ImageSlider images={product?.images} dimensions={dimensions}>
            <div className="text-center mb-2">
              <h3 className="text-gray text-lg font-bold">{product?.name}</h3>
            </div>
            <div className="flex flex-row items-center justify-center gap-2">
              {product?.old && <p className="text-gray text-sm line-through">{product?.old}</p>}
              <p className="text-dark text-lg font-bold">{product?.price}</p>
            </div>
          </ImageSlider>
        </Link>
      </div>
    </div>
  );
};

export default ProductDetail;
