import { faChevronLeft, faChevronRight, faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useRef, useState } from 'react';

const Slider = ({ slides = [], children }: any) => {
  const ctnRef = useRef<HTMLDivElement>(null);

  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const prev = () => {
    const el = ctnRef.current;
    if (el) {
      el.scrollLeft -= el.offsetWidth;
    }
  };

  const next = () => {
    const el = ctnRef.current;
    if (el) {
      el.scrollLeft += el.offsetWidth;
    }
  };

  return (
    <div className="flex items-center justify-center w-full relative">
      <button
        onClick={prev}
        className={`text-gray hover:text-dark h-full absolute left-0 p-2 bg-gradient-to-l from-transparent to-white `} // ${current === 0 && 'opacity-50 cursor-not-allowed'}
        // disabled={current === 0}
      >
        <Icon icon={faChevronLeft} size="xl" />
      </button>
      <div ref={ctnRef} className="flex-1 flex overflow-x-auto no-scrollbar scroll-smooth">
        <div className={`bg-cover bg-center`}>{children}</div>
      </div>
      <button
        onClick={next}
        className={`text-gray hover:text-dark h-full absolute right-0 p-2 bg-gradient-to-r from-transparent to-white `} // ${current === length - 1 && 'opacity-50 cursor-not-allowed'}
        // disabled={current === length - 1}
      >
        <Icon icon={faChevronRight} size="xl" />
      </button>
    </div>
  );
};

export default Slider;
