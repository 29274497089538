"use client";

import BgSlider, {BgSliderItem} from "@/components/bg-slider";
import Link from "next/link";
import Image from "next/image";
import {FC} from "react";


export type ProductLineSliderProps = {
  lines: any[];
}

const ProductLineSlider: FC<ProductLineSliderProps> = ({lines}) => {
  return (
    <BgSlider className={`h-[35vh] overflow-hidden relative`}>
      {lines.map((line: any, key: number) => {
        const image1 = "https://s3.amazonaws.com/media.crayonropadechicos.com.ar/uploads/images/products/1a35bd83-236a-4adf-8ee3-0668b2ed444a-1.jpeg";
        const image2 = "https://s3.us-east-1.amazonaws.com/media.crayonropadechicos.com.ar/uploads/media/image/1712947892625.jpeg";

        const image = key % 2 === 0 ? image1 : image2;

        return (
          <BgSliderItem key={key} className={"absolute inset-0"}>
            <Link href={`/p/lines/${line.name}`} className={"w-full h-full relative object-fit"}>
              <Image src={image} width={1080} height={768} alt={"line"} className="object-cover w-full" priority/>
              <div className="absolute top-[15%] left-[45%] ">
              </div>
            </Link>
          </BgSliderItem>
        )
      })}
    </BgSlider>
  )
}

export default ProductLineSlider
